<section class="speakers-full-width">
	<div class="container-fluid p-0">
		<div class="row">
			<div class="col-md-12 heading">
				<span class="title-icon float-left"><i class="fa fa-weixin"></i></span>
				<a [routerLink]="['/investigadores']">
					<h2 class="title">Investigadores <span class="title-desc">Conoce nuestro equipo</span></h2>
				</a>
			</div>
		</div><!-- Title row end -->
		<div class="row">
			<div class="col-12">
				<div class="speaker-slider">
					<owl-carousel-o [options]="customOptions">
						
						<ng-template carouselSlide *ngFor="let investigador of investigadores" class="speaker-image">
							<div class="speaker-image item">
								<img src="https://www.sipu.ujed.mx/api/api/sipu/dinvestigadores/img/{{ investigador?.vinv_invid }}"
									alt="speaker">
								<div class="primary-overlay text-center">
									<h5>{{ investigador?.vinv_nombre }}</h5>
									<p>{{ investigador?.vinv_titulo }}</p>
									<a [routerLink]="['/investigador', investigador?.vinv_invid]"
										class="mr-2 slider btn btn btn-outline-white white">Ver</a>
								</div>
							</div>
							
						</ng-template>
						
					</owl-carousel-o>
				</div>
			</div>
		</div>
	</div>
</section>