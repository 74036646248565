<app-header></app-header>


<section class="page-title bg-title-exu overlay-fondo">
	<div class="container">
		<div class="row">
			<div class="col-12 text-left">
				<div class="title">
					<h2>EXAUJED</h2>
				</div>
				<ol class="breadcrumb justify-content-left p-0 m-0">
				  <li class="breadcrumb-item"><a href="index.html">´Principal</a></li>
				  <li class="breadcrumb-item active">EXAUJED</li>
				</ol>
			</div>
		</div>
	</div>
</section>

<div class="row">
	<div class="col-md-12 text-center mt-1">
		<h2 class="title2"> EGRESADOS <span class="alternate">UJED</span>
			<span class="title-desc">Encuentra  más noticias  </span>
		</h2>
	</div>
</div><!-- Title row end -->


<div class="news-hr section">
	<div class="container">

		<div class="row col-12 mt-4 mb-5">
			<div class="col-md-6">
			
				<figure class="snip1566">
					<img  src="./assets/images/xujed.jpeg" alt="sq-sample14" />
					<figcaption> <p> CURSOS TALLERES Y BENEFICIOS</p></figcaption>
					<a [routerLink]="['/identidadexa']"></a>
				</figure>
		  		<h4>CURSOS TALLERES Y BENEFICIOS</h4>
		  
			</div>

			<div class="col-md-6">
				<figure class="snip1566"><img src="./assets/images/ujedujed.jpeg"  alt="sq-sample19" />
					<figcaption><p>IDENTIDAD UJED</p></figcaption>
					<a [routerLink]="['/exaujed']"></a>
				</figure>
				<h4>IDENTIDAD UJED</h4>
			
			</div>

		</div>

	</div>
</div>

<app-footer></app-footer>