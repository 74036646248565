<app-header></app-header>

<section class="page-title bg-title-exu overlay-fondo">
  <div class="container">
    <div class="row">
      <div class="col-12 text-left">
        <div class="title">
          <h2>CURSOS TALLERES Y BENEFICIOS</h2>
        </div>
        <ol class="breadcrumb justify-content-left p-0 m-0">
          <li class="breadcrumb-item"><a [routerLink]="['/exaujedprincipal']">Regresar</a></li>
          <li class="breadcrumb-item active">CURSOS TALLERES Y BENEFICIOS</li>
        </ol>
      </div>
    </div>
  </div>
</section>

<div class="row">
  <div class="col-md-12 text-center mt-1">
    <h2 class="title2"> CURSOS TALLERES Y  <span class="alternate">BENEFICIOS</span>
      <span class="title-desc">Conoce los servicios que tenemos para ti</span>
    </h2>
  </div>
</div><!-- Title row end -->


<div class="container mb-5">

  <div class="row">
    <div class="col-md-12 heading">
      <span class="title-icon classic float-left"><i class="fa fa-university"></i></span>
      <h3 class="title classic">FACULTAD</h3>
    </div>

  </div><!-- Title row end -->
  <div class="accordion" id="accordion">
    <div class="card border rounded mb-2">
      <div class="card-header p-0">
        <a class="h4 mb-0 font-weight-bold text-uppercase d-block p-2 pl-5" data-toggle="collapse"
          data-target="#collapseOne" aria-expanded="true" aria-controls="collapseOne">Escuela de Lenguas</a>
      </div>
      <div id="collapseOne" class="collapse show" data-parent="#accordion">
        <div class="card-body">
          <div class="tab-pane pl-md-5 fade show active animated px-3  fadeInLeft" id="tab_1" role="tabpanel">
            <h3> <b>Escuela de Lenguas</b></h3> <br>
            <h4> Tipo de Servicio</h4>

            <ul class="lista-desordenada">
              <li><a href="http://escueladelenguas.ujed.mx/formacion-continua/examenes/">Exámenes de certificación de
                  idioma.</a></li>
              <li><a href="http://escueladelenguas.ujed.mx/formacion-continua/cursos/">Curso de preparación para
                  exámenes de certificación.</a></li>
              <li><a href="http://escueladelenguas.ujed.mx/formacion-continua/traduccion-e-interpretacion/">Servicios de
                  traducción e interpretación.</a></li>
              <li><a href="http://escueladelenguas.ujed.mx/cele/inscripciones/">Cursos de inglés semanales, sabatinos
                  para adolescentes y adultos</a></li>
              <li><a href="http://escueladelenguas.ujed.mx/cele/inscripciones/">Cursos de inglés para niños</a></li>
              <li><a href="http://escueladelenguas.ujed.mx/cele/inscripciones/">Cursos de otros idiomas como: francés,
                  alemán, japonés. Clases de inglés de estudio independiente.</a></li>
            </ul>

          </div>
        </div>
      </div>
    </div>
    <!--/ Panel 1 end-->

    <div class="card border rounded mb-2">
      <div class="card-header p-0">
        <a class="h4 collapsed mb-0 font-weight-bold text-uppercase d-block p-2 pl-5" data-toggle="collapse"
          data-target="#collapseTwo" aria-expanded="true" aria-controls="collapseTwo">Facultad de Derecho y Ciencias
          Políticas</a>
      </div>
      <div id="collapseTwo" class="collapse" data-parent="#accordion">
        <div class="card-body">
          <div class="tab-pane pl-md-5 fade animated fadeInLeft px-3" role="tabpanel">
            <h3> <b>Facultad de Derecho y Ciencias Políticas</b></h3> <br>
            <h4> Tipo de Servicio</h4>

            <p>Servicios legales gratuitos de Asesoría y defensa jurídica en materia de:</p>
            <ul class="lista-desordenada">
              <li class="">Civil.</li>
              <li class="">Familiar</li>
              <li class="">Laboral</li>
              <li>Agrario</li>
            </ul>

            <p>
              <br><b>Contacto:
                <br>LIC. HUMBERTO RAÚL CALDERÓN CHÁVEZ
                <br>618-309-9652</b>
            </p>

          </div>
        </div>
      </div>
    </div>
    <!--/ Panel 2 end-->

    <div class="card border rounded mb-2">
      <div class="card-header p-0">
        <a class="h4 collapsed mb-0 font-weight-bold text-uppercase d-block p-2 pl-5" data-toggle="collapse"
          data-target="#collapseThree" aria-expanded="true" aria-controls="collapseThree">Facultad de Agricultura y
          Zootecnia</a>
      </div>
      <div id="collapseThree" class="collapse" data-parent="#accordion">
        <div class="card-body">
          <div class="tab-pane pl-md-5 fade animated fadeInLeft px-3" role="tabpanel">
            <h3> <b>Facultad de Agricultura y Zootecnia</b></h3> <br>
            <h4> Tipo de Servicio</h4>

            <ul class="lista-desordenada">
              <li>Cursos de capacitación en huertos familiares.</li>
              <li>Cursos de capacitación para el manejo del Nogal, control de plagas y enfermedades.</li>
              <li>Análisis químicos de suelo.</li>
              <li>Publicaciones en la revista AGROFAZ</li>
            </ul>
            <p> Contacto:
              <br>(871)-711-88-76
              <br>(871)-711-88-75</p>
          </div>
        </div>
      </div>
    </div>

    <div class="card border rounded mb-2">
      <div class="card-header p-0">
        <a class="h4 collapsed mb-0 font-weight-bold text-uppercase d-block p-2 pl-5" data-toggle="collapse"
          data-target="#collapseFour" aria-expanded="true" aria-controls="collapseFour">Facultad de Ciencias
          Biológicas</a>
      </div>
      <div id="collapseFour" class="collapse" data-parent="#accordion">
        <div class="card-body">
          <div class="tab-pane pl-md-5 fade animated fadeInLeft px-3" role="tabpanel">
            <h3> <b>Facultad de Ciencias Biológicas</b></h3> <br>
            <h4> Tipo de Servicio</h4>

            <ul class="lista-desordenada">
              <a href="https://mx.fcbujed.com/mx/">
                <li>Asesoría en acuicultura para peces de agua dulce.</li>
                <li>Estudios de peces de ambientes naturales para minería.</li>
                <li>Elaboración de planes de manejo pesqueros.</li>
                <li>Análisis de Composición de biomasa (proteínas, aminoácidos, nitratos y azúcares).</li>
                <li>Arquitectura de Paisaje.</li>
                <li>Asesoría para reforestación.</li>

                <li>Identificación molecular de bacterias y hongos.</li>
                <li>Inoculación de bacterias en cultivos de interés agrícola (Biofertilizantes).</li>
                <li>Análisis Microbiológico de Plantas y Suelo.</li>
                <li>Análisis Clínico Veterinario.</li>
                <li>Determinación de edades de arboles en sistemas forestales y agroforestales.</li>
                <li>Estudio de Diversidad Biológica.</li>
                <li>Diagnóstico fitosanitario de plagas y enfermedades agrícolas.</li>

                <li>Muestreos de Vegetación.</li>
                <li>Identificación de raíces vegetales.</li>
                <li>Determinación de especies con fines de investigación taxonómica y publicación.</li>
                <li>Estudios de impacto ambiental.</li>
                <li>Estudio técnico justificativo para llevar a cabo proyectos en materia de cambios de uso de suelos en
                  terrenos forestales.</li>
                <li>Unidades de Manejo para la conservación de vida silvestre.</li>
                <li>Elaboración de planes de rescate de flora y fauna.</li>
                <li>Atlas de Riesgos.</li>
                <li>Cedula de Operación Anual.</li>
                <li>Estudio Justificativo de características especiales para ductos y chimeneas.</li>

                <li>Estudios de Factibilidad Ambiental.</li>
                <li>Estudios de impacto Ambiental (Particular y Regional).</li>
                <li>Estudios de Riesgo Ambiental.</li>
                <li>Estudios Previos Justificativos para el establecimiento de Áreas NaturalesProtegidas.</li>
                <li>Evaluaciones de la vulnerabilidad de ecosistemas y especies ante elcambio climático.</li>
                <li>Inventarios de Flora y Fauna.</li>
                <li>Trámite de Licencia Ambiental Única.</li>

                <li>Ordenamientos Ecológicos Territoriales y Ordenamientos TerritorialesComunitarios.</li>
                <li>Planes de Acción Climática Municipal.</li>
                <li>Planes Estatales de Acción ante el Cambio Climático.</li>
                <li>Programas de Manejo de Áreas Naturales Protegidas.</li>
                <li>Programas de Monitoreo Biológico Comunitario.</li>
                <li>Programas de Pago por Servicios Ambientales.</li>

                <li>Programas de Reforestación.</li>
                <li>Valoración Económica de Servicios Ambientales.</li>
                <li>Determinación de calidad de agua y de suelo.</li>
                <li>Determinación de calidad de tejido vegetal y animal.</li>
                <li>Asesoría en la identificación de insectos de importancia agrícola.</li>
                <li>Capacitación en el Manejo Integrado de Plagas agrícolas.</li>
                <li>Asesoría en preparación de grupos de instituciones de educación en el conocimiento de los insectos.
                </li>
                <li>Asesoría en el manejo y control de reptiles peligrosos.</li>
                <li>Identificación de plantas basculares.</li>
                <li>Análisis Bio geográficos.</li>
                <li>Análisis de relaciones filogenéticas.</li>
                <li>Cría de Mosquitos para la realización de pruebas con insecticidas.</li>
                <li>Bio Ensayos: Evaluación de insecticidas en mosquitos.</li>
                <li>Identificación de Mosquitos.</li>
                <li>Asesorías a instituciones para la prevención de enfermedades transmitidas por vectores.</li>
              </a>
            </ul>


          </div>
        </div>
      </div>
    </div>

    <div class="card border rounded mb-2">
      <div class="card-header p-0">
        <a class="h4 collapsed mb-0 font-weight-bold text-uppercase d-block p-2 pl-5" data-toggle="collapse"
          data-target="#collapseFive" aria-expanded="true" aria-controls="collapseFive">Facultad de Ciencias Químicas de
          Durango</a>
      </div>
      <div id="collapseFive" class="collapse" data-parent="#accordion">
        <div class="card-body">
          <div class="tab-pane pl-md-5 fade animated fadeInLeft px-3 " id="tab_5" role="tabpanel">

            <h3> <b>Facultad de Ciencias Químicas de Durango</b></h3> <br>
            <h4> Tipo de Servicio</h4>
            <ul class="lista-desordenada">
              <h4><b>Análisis Clínicos de:</b></h4>
              <li>Antiestreptolisinas</li>
              <li>Antígeno específico de próstata</li>
              <li>Amiba en fresco</li>
              <li>Azucares reductores</li>
              <li>Albúmina sérica</li>
              <li>Ácido úrico</li>
              <li>BAAR una muestra</li>
              <li>BAAR seriado</li>
              <li>Biometría hemática completa</li>
              <li>Bilirrubinas totales y fraccionadas</li>
              <li>Calcio</li>
              <li>Cultivo faríngeo</li>
              <li>Coprocultivo</li>
              <li>Cloro</li>
              <li>Citología en moco fecal</li>
              <li>Colesterol total</li>
              <li>Coombs indirecto</li>
              <li>Coproparasitoscópico (1)</li>
              <li>Coproparasitoscópico (3)</li>
              <li>Coprológico incluye sangre oculta</li>
              <li>Creatinina</li>
              <li>Células LE</li>
              <li>Urocultivo</li>
              <li>Depuración de creatinina en orina de 12 o 24 h</li>
              <li>Exudado faríngeo</li>
              <li>Examen general de orina</li>
              <li>Eosinofilia nasal (primera muestra)</li>
              <li>Eosinofilia nasal (tercera muestra)</li>
              <li>Espermatobioscopia</li>
              <li>Electrolitos séricos (Sodio, Potasio y Cloro)</li>
              <li>Fósforo</li>
              <li>Fosfatasa alcalina</li>
              <li>Factor reumatoide</li>
              <li>Glucosa sérica</li>
              <li>Grupo sanguíneo y RH</li>
              <li>Hemoglobina glucosilada</li>
              <li>HDL</li>
              <li>HGC (cualitativa sangre y orina)</li>
              <li>HIV I y II</li>
              <li>Magnesio</li>
              <li>Sodio</li>
              <li>Potasio</li>
              <li>Proteína C reactiva</li>
              <li>Prueba de función hepática (BT, BD, BI, TGO, TGP)</li>
              <li>Pruebas de función hepática (BT, BD, BI, TGO, TGP, PROTEINAS, ALBUMINA, GLOBULINA, R/Ag)</li>
              <li>Perfil de lípidos (colesterol total, triglicéridos, HDL, LDL, VLDL, índice aterogénico)</li>
              <li>Proteínas en orina de 12 o 24 hrs</li>
              <li>Perfil reumatoide (PCR, FR, AEL, VSG, Ac, úrico)</li>
              <li>Prenupciales 1 (VDRL, Grupo y RH)</li>
              <li>Prenupciales 2 (VDRL, Grupo, RH, HIV)</li>
              <li>Prenupciales 3 (VDRL, Grupo RH; HIV, Glucosa)</li>
              <li>Perfil 1 (QS (4) BH y EGO)</li>
              <li>Perfil 2 (QS (6) BH y EGO)</li>
              <li>Perfil 3 (QS (4), P. LIPIDOS. BH, EGO)</li>
              <li>Prenatales (BH, grupo y RH, glucosa, EGO y VDRL)</li>
              <li>Preoperatorios (BH, TP, TPT y grupo y RH)</li>
              <li>Proteínas totales suero</li>
              <li>Química sanguínea 1 (glucosa, urea y creatinina)</li>
              <li>Química sanguínea 2 (glucosa, urea, creatinina, Ácido úrico)</li>
              <li>Química sanguínea 3 (glucosa, urea, creatinina, Ácido úrico, colesterol y triglicéridos)</li>
              <li>Reticulocitos</li>
              <li>Química sanguínea 5</li>
              <li>Química sanguínea 6 y perfil de lípidos</li>
              <li>Química sanguínea 6</li>
              <li>Reacciones febriles</li>
              <li>Rotavirus</li>
              <li>Sangre oculta en heces</li>
              <li>Triglicéridos</li>
              <li>TGO</li>
              <li>TGP</li>
              <li>Tiempo de protrombina</li>
              <li>Tiempo de protrombina y tiempo parcial tromboplastina</li>
              <li>Tiempo parcial tromboplastina</li>
              <li>Urea</li>
              <li>VDRL</li>
              <li>Velocidad de sedimentación</li>

              <h4><b>Análisis Toxicológicos</b></h4>
              <li>Cocaína</li>
              <li>Anfetaminas</li>
              <li>Metanfetaminas</li>
              <li>Benzodiacepinas</li>
              <li>Barbitúricos</li>
              <li>Opiáceos</li>
              <li>Marihuana</li>
              <li>Antidoping (COC-ANF-MET-TCH)</li>
              <li>Antidoping (COC-ANF-MET-TCH-BZD)</li>
              <li>Antidoping (COC-ANF-MET-TCH-BZD-BAR)</li>
              <li>Antidoping (COC-ANF-MET-TCH-BZD-BAR-OPI)</li>
              <li>Licencia (COC-ANF-MET-TCH-GRUPO SANGUINEO Y GLUCOSA)</li>

              <h4><b>Análisis Microbiológicos de Alimentos</b></h4>
              <li>Mesófilos aerobios</li>
              <li>Coliformes totales</li>
              <li>Hongos y levaduras</li>
              <li>Superficies vivas e inertes</li>
              <li>Salmonella</li>
              <li>Staphylococcus aureus</li>
              <li>Vibrio cholerae</li>
              <li>Coliformes fecales</li>
              <li>Microbiológico de agua (Coliformes totales y fecales)</li>
              <li>E. coli</li>
              <li>Fisicoquímicos de agua</li>

              <h4><b>Análisis Bromatológicos</b></h4>
              <li>Humedad y sólidos totales</li>
              <li>Cenizas</li>
              <li>Proteínas</li>
              <li>Grasas totales</li>
              <li>Grasas saturadas y trans</li>
              <li>Carbohidratos disponibles</li>
              <li>Azúcares totales</li>
              <li>Fibra</li>
              <li>Sodio</li>
              <li>Contenido energético</li>
              <li>Tabla nutrimental con grasas saturadas y trans</li>
              <li>Tabla nutrimental sin grasas saturadas y trans</li>

              <h4><b>Análisis Químico Elemental</b></h4>
              <li>Oro</li>
              <li>Cobre</li>
              <li>Plata</li>
              <li>Plomo</li>
              <li>Paladio</li>
              <li>Zinc</li>
              <li>Platino</li>
              <li>Fierro</li>
              <li>Iridio</li>
              <li>Aluminio</li>
              <li>Osmio</li>
              <li>Cromo</li>
              <li>Rodio</li>
              <li>Titanio</li>
              <li>Rutenio</li>
              <li>Cadmio</li>
              <li>Níquel</li>
              <li>Potasio</li>
              <li>Sodio</li>
              <li>Calcio</li>
              <li>Fósforo</li>
              <li>Manganeso</li>
              <li>Aluminio </li>
              <li>Sodio</li>
              <li>Cadmio</li>

              <h4><b>Control de Plagas Urbanas</b></h4>
              <li>Cucaracha</li>
              <li>Chinches</li>
              <li>Arácnidos</li>
              <li>Roedores</li>
              <li>Otros</li>
            </ul>
          </div>
        </div>
      </div>
    </div>
    <div class="card border rounded mb-2">
      <div class="card-header p-0">
        <a class="h4 collapsed mb-0 font-weight-bold text-uppercase d-block p-2 pl-5" data-toggle="collapse"
          data-target="#collapseSix" aria-expanded="true" aria-controls="collapseSix">Facultad de Ciencias Químicas de
          Gómez</a>
      </div>
      <div id="collapseSix" class="collapse" data-parent="#accordion">
        <div class="card-body">
          <div class="tab-pane pl-md-5 fade animated fadeInLeft px-3" role="tabpanel">
            <h3> <b>Facultad de Ciencias Químicas de Gómez</b></h3> <br>
            <h4> Tipo de Servicio</h4>

            <ul class="lista-desordenada">
              <h4> <b>PAQUETES</b></h4>

              <li>Prenupcial I.</li>
              <li>Prenupcial II.</li>
              <li>Prenatal I.</li>
              <li>Prenatal II</li>
              <li>Análisis general I.</li>
              <li>Análisis general II</li>
              <li>Perfil hormonal completo.</li>
              <li>Pruebas de funcionamiento hepático.</li>
              <li>Perfil reumático.</li>
              <li> Perfil tiroideo I.</li>
              <li> Perfil tiroideo II.</li>
              <li>Estudio de Diversidad Biológica.</li>
              <li>Perfil lipídico.</li>

              <h4><b>ESTUDIOS</b></h4>
              <li>Glucosa</li>
              <li>Colesterol</li>
              <li>Triglicéridos</li>
              <li>Coprológico</li>
              <li>Examen general de orina (ego)</li>
              <li>Prueba de embarazo en sangre y orina</li>
              <li>Antiestreptolisinas</li>
              <li>Factor reumatoide</li>
              <li>Antidoping Cualitativo</li>
              <li>Cultivos</li>

              <h4><b>PAQUETE 1</b></h4>
              <li>Examen General De Orina (Ego)</li>
              <li>Coproparasitoscópico Seriado (3 Muestras)</li>
              <li>Exudado Faríngeo</li>

              <h4><b>PAQUETE 2</b></h4>
              <li>Examen General De Orina (Ego)</li>
              <li>Coproparasitoscópico Seriado (3 Muestras)</li>
              <li>Exudado Faríngeo</li>
              <li>Biometría Hemática Completa Y Grupo Sanguíneo</li>
            </ul>

            <p>CONTACTO:
              <br>Tel (871)7141349 o 7158810 ext.190
              <br>Correo: lac_ujed@hotmail.com
              <br>Facebook: Laboratorio de Análisis Clínicos FCQ UJED
              <br>RESPONSABLE DEL LABORATORIO
              <br>Q.F.B Ma. Guadalupe Ernestina González Yáñez</p>

          </div>

        </div>
      </div>
    </div>
    <div class="card border rounded mb-2">
      <div class="card-header p-0">
        <a class="h4 collapsed mb-0 font-weight-bold text-uppercase d-block p-2 pl-5" data-toggle="collapse"
          data-target="#collapseSeven" aria-expanded="true" aria-controls="collapseSeven">Instituto de Inventigaciones
          Científicas</a>
      </div>
      <div id="collapseSeven" class="collapse" data-parent="#accordion">
        <div class="card-body">


          <div class="tab-pane pl-md-5 fade animated fadeInLeft px-3 " id="tab_7" role="tabpanel">
            <h3> <b>Instituto de Inventigaciones Científicas</b></h3> <br>
            <h4> Tipo de Servicio</h4>

            <div class="accordion" id="accordion">

              <div class="card-body">
                <ul class="lista-desordenada">
                  <h4><b>HEMATOLOGIA CLINICA.</b></h4>
                  <li>BHC 98.00</li>
                  <li>Grupo Sanguíneo 98.0</li>
                  <li>Tiempos de Coagulación 98.00</li>
                  <li>Tiempo de Sangrado 98.00</li>
                  <li>Tiempo de Protrombina 98.0</li>
                  <li>Tiempo de Trombina 98.0</li>
                  <li>Tiempo de tromboplastina 98.0</li>

                  <h4><b>QUIMICA CLINICA.</b></h4>
                  <li>Glucosa 69.0</li>
                  <li>Glucosa Post-pandrial 115.0</li>
                  <li>Curva de Tolerancia a la Glucosa 184.0</li>
                  <li>Triglicéridos 76.0</li>
                  <li>Creatinina 69.0</li>
                  <li>Colesterol 69.0</li>
                  <li>Urea 69.0</li>
                  <li>Química 6 Elementos 300.0</li>

                  <h4><b>PERFIL DE RUTINA.</b></h4>
                  <li>Perfil Hepático 443.0</li>
                  <li>Perfil de Lípidos 440.0</li>
                  <li>Perfil Tiroideo 374.0</li>

                  <h4><b>UROANALISIS.</b></h4>
                  <li>Examen General de Orina. 76.0</li>

                  <h4><b>COPROLOGIA.</b></h4>
                  <li>Coproparasitoscópico Serie. 126.0</li>
                  <li>Coprológico. 244.0</li>
                  <li>Sangre oculta en heces. 51.0</li>


                  <h4><b>SEROLOGIA.</b></h4>
                  <li>Reacciones Febriles. 103.0</li>
                  <li>Pruebas de Embarazo 184.0</li>
                  <li>Prueba VIH 253.0</li>
                  <li>VDRL 86.0</li>
                  <li>Factor Reumatoide 86.0</li>
                  <li>Antiestreptolisinas 86.0</li>
                  <li>PCR. 86.0</li>
                </ul>
              </div>
            </div>
          </div>

        </div>
      </div>
    </div>
    <div class="card border rounded mb-5">
      <div class="card-header p-0">
        <a class="h4 collapsed mb-0 font-weight-bold text-uppercase d-block p-2 pl-5" data-toggle="collapse"
          data-target="#collapseEight" aria-expanded="true" aria-controls="collapseEight">Facultad de Odontología</a>
      </div>
      <div id="collapseEight" class="collapse" data-parent="#accordion">
        <div class="card-body">
          <div class="tab-pane pl-md-5 fade animated fadeInLeft px-3 " id="tab_8" role="tabpanel">
            <h3> <b>Facultad de Odontología </b></h3> <br>
            <h4> Tipo de Servicio</h4>

            <ul class="lista-desordenada">
              <li>Limpiezas Dentales</li>
              <li>Limpiezas profundas (raspados)</li>
              <li>Cirugías periodontales</li>
              <li>Extracciones Quirúrgicas</li>
              <li>Alargamientos estéticos y de corona</li>
              <li>Colocación de Implantes</li>
            </ul>
          </div>
        </div>
      </div>
    </div>


  </div>
</div>


<app-footer></app-footer>