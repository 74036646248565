import { RouterModule, Routes } from '@angular/router';
import { MainPageComponent } from '../mainPage/mainPage.component';
import { FooterComponent } from '../footer/footer.component';
import { HeaderComponent } from '../header/header.component';
import { SlideComponent } from '../mainPage/slide/slide.component';
import { ServiceComponent } from '../mainPage/service/service.component';
import { OfertaEducativamComponent } from '../mainPage/ofertaeducativam/ofertaeducativam.component';
import { ExaujedmComponent } from '../mainPage/exaujedm/exaujedm.component';
import { SipumComponent } from '../mainPage/sipum/sipum.component';
import { InvestigadoresmComponent } from '../mainPage/investigadoresm/investigadoresm.component';
import { LoginComponent } from '../login/login.component';
import { DashboardComponent } from '../dashboard/dashboard.component';
import { OfertaEducaComponent } from '../ofertaeduca/ofertaeduca.component';
import { SipuComponent } from '../sipu/sipu.component';
import { CursosdiplomadosComponent } from '../cursosdiplomados/cursosdiplomados.component';
import { PoliticasreglamentosComponent } from '../politicasreglamentos/politicasreglamentos.component';
import { InvestigadoresComponent } from '../investigadores/investigadores.component';
import { InvestigComponent } from '../investigadores/investig/investig.component';
import { IdentidadComponent } from '../identidad/identidad.component';
import { ExaujedprincipalComponent } from '../exaujedprincipal/exaujedprincipal.component';
import { ExaujedComponent } from '../exaujedprincipal/exaujed/exaujed.component';
import { ExaujedinfoComponent } from '../exaujedprincipal/exaujed/exaujedinfo/exaujedinfo.component';
import { ResultadosdipiComponent } from '../resultadosdipi/resultadosdipi.component';
import { ProgramainfoComponent } from '../ofertaeduca/programainfo/programainfo.component';
import { NoticiasComponent } from '../noticias/noticias.component';
import { NoticiainfoComponent } from '../noticias/noticiainfo/noticiainfo.component';
import { DirectorioComponent } from '../directorio/directorio.component';
import { ConvoexternasComponent } from '../convoexternas/convoexternas.component';
import { ConvoexternasinfoComponent } from '../convoexternas/convoexternasinfo/convoexternasinfo.component';
import { ListaconvocatoriasComponent } from '../ofertaeduca/listaconvocatorias/listaconvocatorias.component';
import { CitaapaComponent } from '../citaapa/citaapa.component';
import { IdentidadexaComponent } from '../exaujedprincipal/identidadexa/identidadexa.component';
//import { LoginGuard } from '../../services/guards/login.guard';

const routes: Routes = [
	//MAIN PAGE 
  
	{
	  path: '',
	  component: MainPageComponent,
	  children: [
		{ path: 'slide', component: SlideComponent },
		{ path: 'service', component: ServiceComponent },
		{ path: 'ofertaeducativam', component: OfertaEducativamComponent },
		{ path: 'sipum', component: SipumComponent },
		{ path: 'investigadoresm', component: InvestigadoresmComponent },
		{ path: 'exaujedm', component: ExaujedmComponent },
	  ]
	},
	//PAGES
	{ path: 'login', component: LoginComponent },
	{ path: 'dashboard', component: DashboardComponent },
	{ path: 'ofertaeduca', component: OfertaEducaComponent },
	{ path: 'sipu', component: SipuComponent },
	{ path: 'cursosdiplomados', component: CursosdiplomadosComponent },
	{ path: 'politicasreglamentos', component: PoliticasreglamentosComponent },
	{ path: 'investigadores', component: InvestigadoresComponent },
	{ path: 'investigador/:id', component: InvestigComponent },
  
	{ path: 'identidad', component: IdentidadComponent },
	{ path: 'exaujedprincipal', component: ExaujedprincipalComponent },
	{ path: 'exaujed', component: ExaujedComponent },
	{ path: 'exaujedinfo/:id', component: ExaujedinfoComponent },
	{ path: 'identidadexa', component: IdentidadexaComponent },
	{ path: 'resultadosdipi', component: ResultadosdipiComponent },
	{ path: 'programainfo/:id', component: ProgramainfoComponent },
	{ path: 'noticias', component: NoticiasComponent },
	{ path: 'noticiainfo', component: NoticiainfoComponent },
	{ path: 'noticiainfo/:id', component: NoticiainfoComponent },
  	{ path: 'directorio', component: DirectorioComponent },
	{ path: 'convoexterno', component: ConvoexternasComponent },
	{ path: 'convoexternoinfo/:id', component: ConvoexternasinfoComponent },
	{ path: 'listaconvocatorias', component: ListaconvocatoriasComponent, },
	{ path: 'citaapa', component: CitaapaComponent, },

  ];


export const PAGES_ROUTES = RouterModule.forChild(routes);
