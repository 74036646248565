import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { BlockUI, NgBlockUI } from 'ng-block-ui';
import { Dbannerportal } from 'src/app/models/dbannerportal';
import { Dnoticiasportal } from 'src/app/models/dnoticiasportal';
import { SipuService } from 'src/app/services/sipu.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-identidadexa',
  templateUrl: './identidadexa.component.html',
  styleUrls: ['./identidadexa.component.css']
})
export class IdentidadexaComponent implements OnInit {
  @BlockUI() blockUI: NgBlockUI;

  urlEndPoint = `${environment.rutaAPISipu}`;

  id: number;

  noticia:Dnoticiasportal;
  detalle:Dbannerportal[];

  tieneImagenes:boolean=false;

  constructor(private activatedRoute: ActivatedRoute, private _ss: SipuService) { }

  ngOnInit(): void {
    window.scroll(0, 0);

  }

}
