<app-header></app-header>
<block-ui>

<section class="page-title bg-title-exu overlay-fondo">
	<div class="container">
		<div class="row">
			<div class="col-12 text-left">
				<div class="title">
					<h2>IDENTIDAD UJED</h2>
				</div>
				<ol class="breadcrumb justify-content-left p-0 m-0">
				  <li class="breadcrumb-item"><a [routerLink]="['/exaujedprincipal']">Regresar</a></li>
				  <li class="breadcrumb-item active">IDENTIDAD UJED</li>
				</ol>
			</div>
		</div>
	</div>
</section>

<div class="row">
	<div class="col-md-12 text-center mt-1">
		<h2 class="title2"> IDENTIDAD <span class="alternate">UJED</span>
			<span class="title-desc">Conoce las ultimas Noticias de Nuestros Egresados</span>
		</h2>
	</div>
</div><!-- Title row end -->


<section class="news-hr section mb-0">
	<div class="container">

		<div class="row no-gutters">

			<div class="col-lg-6 col-md-6 mb-4"  *ngFor="let item of notiegresados | paginate: { itemsPerPage: 9, currentPage: currentPage }">
				<a [routerLink]="['/exaujedinfo',item.dnot_notid]">
				<article class="news-post-hr">
					<div class="post-thumb">
						<a href="news-single.html">
							<img [src]="urlEndPoint + '/dnoticiasportal/img/' + item.dnot_notid + '?not_imagenHashCode=' + item.not_imagenHashCode"
							 alt="post-image" width="100%" height="270px">
						</a>
					</div>
					<div class="post-contents border-top">
						<div class="post-title">
							<h6><a>{{item.dnot_titulo}}</a></h6>
						</div>
						<div class="post-exerpts">
							<p>{{item.dnot_observaciones}}
							</p>
						</div>

						<div class="date">
							<h4><span>{{item.dnot_fec}}</span></h4>
						</div>
						<div class="more">
							<a href="news-single.html">Mostrar más</a>
						</div>
					</div>
				</article>
				</a>
			</div>

			<div class="col-12 text-center">
				<nav class="d-flex justify-content-center">
				  <ul class="pagination">
					<li class="page-item" [class.disabled]="currentPage === 1">
						<a class="page-link" (click)="goToPreviousPage()" aria-label="Previous">
						  <span aria-hidden="true">&laquo;</span>
						  <span class="sr-only">Anterior</span>
						</a>
					  </li>
					<li class="page-item" *ngFor="let page of totalPages()" [class.active]="currentPage === page">
					  <a class="page-link"  (click)="currentPage = page">{{ page }}</a>
					</li>
					<li class="page-item" [class.disabled]="!totalPages().includes(currentPage + 1)">
						<a class="page-link" (click)="goToNextPage()" aria-label="Next">
						  <span aria-hidden="true">&raquo;</span>
						  <span class="sr-only">Siguiente</span>
						</a>
					  </li>
				  </ul>
				</nav>
			  </div>

			<br>

		</div>
	</div>
</section>
</block-ui>

<app-footer></app-footer>