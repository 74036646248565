import { Component, OnInit, ViewChild } from '@angular/core';
import { CarouselComponent, OwlOptions } from 'ngx-owl-carousel-o';
import { SipuService } from 'src/app/services/sipu.service';

@Component({
  selector: 'app-investigadoresm',
  templateUrl: './investigadoresm.component.html',
  styleUrls: ['./investigadoresm.component.scss']
})
export class InvestigadoresmComponent implements OnInit {

  @ViewChild('carousel', { static: false }) carousel: CarouselComponent;

  customOptions: OwlOptions = {
    loop: true,
    mouseDrag: true,
    touchDrag: true,
    pullDrag: true,
    dots: true,
    navSpeed: 200,
    navText: [
      '<a class="left carousel-control carousel-control-prev"><span><i class="fa fa-angle-left"></i></span></a>',
      '<a class="right carousel-control carousel-control-next"><span><i class="fa fa-angle-right"></i></span></a>',
    ],
    
    
    
    responsive: {
      0: {
        items: 1
      },
      400: {
        items: 2
      },
      740: {
        items: 3
      },
      940: {
        items: 4
      }
    },
    nav: true,
    autoplay: false,          // Añadido para el desplazamiento automático
    autoplayTimeout: 2000    // Tiempo en milisegundos entre transiciones
  }
  

  
  investigadores: any;
  constructor(private _ss: SipuService) { }

  ngOnInit(): void {

    this._ss.listarInvestigador().subscribe(investigadores => {
      this.investigadores = investigadores;  
      console.log("INVESIGADORES" , this.investigadores);

     

    },
    error => {
      console.log(error);
    });

  }
 
  prevSlide() {
    this.carousel.prev();
}

nextSlide() {
    this.carousel.next();
}

}
