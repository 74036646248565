import { NgModule  } from '@angular/core';
import { CommonModule } from '@angular/common';

import { RouterModule } from '@angular/router';
import { PAGES_ROUTES } from './default.routes';
import { BrowserModule } from '@angular/platform-browser';
import { BlockUIModule } from 'ng-block-ui';

import { DatePipe } from '@angular/common';
import { MainPageComponent } from '../mainPage/mainPage.component';
import { FooterComponent } from '../footer/footer.component';
import { HeaderComponent } from '../header/header.component';
import { SlideComponent } from '../mainPage/slide/slide.component';
import { ServiceComponent } from '../mainPage/service/service.component';
import { OfertaEducativamComponent } from '../mainPage/ofertaeducativam/ofertaeducativam.component';
import { ExaujedmComponent } from '../mainPage/exaujedm/exaujedm.component';
import { SipumComponent } from '../mainPage/sipum/sipum.component';
import { InvestigadoresmComponent } from '../mainPage/investigadoresm/investigadoresm.component';
import { LoginComponent } from '../login/login.component';
import { DashboardComponent } from '../dashboard/dashboard.component';
import { OfertaEducaComponent } from '../ofertaeduca/ofertaeduca.component';
import { SipuComponent } from '../sipu/sipu.component';
import { CursosdiplomadosComponent } from '../cursosdiplomados/cursosdiplomados.component';
import { PoliticasreglamentosComponent } from '../politicasreglamentos/politicasreglamentos.component';
import { InvestigadoresComponent } from '../investigadores/investigadores.component';
import { InvestigComponent } from '../investigadores/investig/investig.component';
import { IdentidadComponent } from '../identidad/identidad.component';
import { ExaujedprincipalComponent } from '../exaujedprincipal/exaujedprincipal.component';
import { ExaujedComponent } from '../exaujedprincipal/exaujed/exaujed.component';
import { ExaujedinfoComponent } from '../exaujedprincipal/exaujed/exaujedinfo/exaujedinfo.component';
import { ResultadosdipiComponent } from '../resultadosdipi/resultadosdipi.component';
import { ProgramainfoComponent } from '../ofertaeduca/programainfo/programainfo.component';
import { NoticiasComponent } from '../noticias/noticias.component';
import { NoticiainfoComponent } from '../noticias/noticiainfo/noticiainfo.component';
import { DirectorioComponent } from '../directorio/directorio.component';
import { ConvoexternasComponent } from '../convoexternas/convoexternas.component';
import { ConvoexternasinfoComponent } from '../convoexternas/convoexternasinfo/convoexternasinfo.component';
import { ListaconvocatoriasComponent } from '../ofertaeduca/listaconvocatorias/listaconvocatorias.component';
import { IdentidadexaComponent } from '../exaujedprincipal/identidadexa/identidadexa.component';

import { SanitizeHtmlPipe } from '../noticias/noticiainfo/sanitize-html.pipe';
import { CitaapaComponent } from '../citaapa/citaapa.component';
import { NgxPaginationModule } from 'ngx-pagination';
import { CarouselModule } from 'ngx-owl-carousel-o';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';

@NgModule({
  declarations: [

    MainPageComponent,
    FooterComponent,
    HeaderComponent,
    SlideComponent,
    ServiceComponent,
    OfertaEducativamComponent,
    ExaujedmComponent,
    SipumComponent,
    InvestigadoresmComponent,

    LoginComponent,
    DashboardComponent,
    OfertaEducaComponent,
    SipuComponent,
    CursosdiplomadosComponent,
    PoliticasreglamentosComponent,
    InvestigadoresComponent,
    InvestigComponent,
    IdentidadComponent,
    ExaujedprincipalComponent,
    ExaujedComponent,
    ExaujedinfoComponent,
    ResultadosdipiComponent,
    ProgramainfoComponent,
    NoticiasComponent,
    NoticiainfoComponent,
    DirectorioComponent,
    ConvoexternasComponent,
    ConvoexternasinfoComponent,
    ListaconvocatoriasComponent,
    IdentidadexaComponent, 
    SanitizeHtmlPipe,
    CitaapaComponent,
    
  ],
    exports: [

       
       
    ],
  imports: [
    CommonModule,
    BrowserModule,
    BrowserAnimationsModule,
    RouterModule,
    BlockUIModule.forRoot(),
    NgxPaginationModule,
    PAGES_ROUTES,
    CarouselModule


  ],
  providers: [
  ],
  entryComponents:[],
  //schemas: [ CUSTOM_ELEMENTS_SCHEMA ]
})
export class DefaultModule { }
