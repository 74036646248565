import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { BlockUI, NgBlockUI } from 'ng-block-ui';
import { Dbannerportal } from 'src/app/models/dbannerportal';
import { Dnoticiasportal } from 'src/app/models/dnoticiasportal';
import { SipuService } from 'src/app/services/sipu.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-noticiainfo',
  templateUrl: './noticiainfo.component.html',
  styleUrls: ['./noticiainfo.component.css']
})
export class NoticiainfoComponent implements OnInit {
  @BlockUI() blockUI: NgBlockUI;

  urlEndPoint = `${environment.rutaAPISipu}`;

  id: number;

  noticia:Dnoticiasportal;
  detalle:Dbannerportal[];

  tieneImagenes:boolean=false;

  constructor(private activatedRoute: ActivatedRoute, private _ss: SipuService) { }

  ngOnInit(): void {
    window.scroll(0, 0);

    
    this.activatedRoute.params.subscribe(params => {
      this.id = params['id']
      
      //console.log("id: "+this.id);
      if (this.id != 0) {
        this.blockUI.start('Cargando...');
        this._ss.verNoticia(this.id).subscribe(noticias => {
          this.noticia = noticias as Dnoticiasportal;
          console.log("noticia "+this.noticia);
          this.detalle=this.noticia.detalle;
          console.log("detalle "+this.detalle.length);
          if (this.detalle.length>0) {
            this.tieneImagenes=true;

          }

          console.log('tieneImagenes',this.tieneImagenes)

           this.blockUI.stop(); 
        },
        error => {
          console.log(error);
        });
      }

    })
  }

}
