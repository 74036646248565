<app-header></app-header>

<section class="page-title bg-title-polregl overlay-fondo">
	<div class="container">
		<div class="row">
			<div class="col-12 text-left">
				<div class="title">
					<h2>Politicas y reglamentos</h2>
				</div>
				<ol class="breadcrumb justify-content-left p-0 m-0">
					<li class="breadcrumb-item"><a href="index.html">´Principal</a></li>
					<li class="breadcrumb-item active">Politicas y reglamentos</li>
				</ol>
			</div>
		</div>
	</div>
</section>



<!-- Pricing table start -->
<section id="pricing" class="pricing">
	<div class="container">
		<div class="row">
			<div class="col-md-12 heading">
				<span class="title-icon float-left"><i class="fa fa-university"></i></span>
				<h2 class="title">NORMATIVAS <span class="title-desc">Dirección Institucional
						de Posgrado e investigación</span></h2>
			</div>
		</div><!-- Title row end -->


		<div class="widget widget-tab">
			<ul class="nav nav-tabs">
				<li class="nav-item"><a class="nav-link active" href="#tab1" data-toggle="tab"> Reglamentos generales
					</a></li>
				<li class="nav-item"><a class="nav-link" href="#tab2" data-toggle="tab"> Reglamentos DEP </a></li>
			</ul>
			<div class="tab-content">

				<div class="tab-pane active" id="tab1">
					<div class="row">
						<div class="col-md-6 col-lg-2 wow fadeInUp mt-5" data-wow-delay="1s">
							<div class="plan text-center featured">
								<span class="plan-name">Reglamento General Estudios Posgrado<small>PDF</small></span>
								<img src="./assets/images/reglamentos/REGLAMENTO_GENERAL_ESTUDIOS_POSGRADO.jpg"
									alt="Imagen PDF" class="pdf-image">
								<a class="btn btn-outline-danger"
									href="https://sistemas.ujed.mx/manualesdipi/REGLAMENTO_GENERAL_ESTUDIOS_POSGRADO.pdf">Descargar</a>
							</div>
						</div><!-- plan end -->

						<div class="col-md-6 col-lg-2 wow fadeInUp mt-5" data-wow-delay="1s">
							<div class="plan text-center featured">
								<span class="plan-name">Reglamento Regulación Oferta Educativa <small>PDF</small></span>
								<img src="./assets/images/reglamentos/reglamentos.jpeg" alt="Imagen PDF"
									class="pdf-image">
								<a class="btn btn-outline-danger"
									href="https://sistemas.ujed.mx/manualesdipi/Reglamento_para_la_regulación_de_la_oferta_educativa.pdf">Descargar</a>
							</div>
						</div><!-- plan end -->

						<!-- reglamento año Sabatico -->
						<div class="col-md-6 col-lg-2 wow fadeInUp mt-5" data-wow-delay="1s">
							<div class="plan text-center featured">
								<span class="plan-name">Reglamento de año sabático <small>PDF</small></span>
								<img src="./assets/images/reglamentos/Reglamento_de_año_sabático.jpg" alt="Imagen PDF"
									class="pdf-image">
								<a class="btn btn-outline-danger"
									href="https://sistemas.ujed.mx/manualesdipi/Reglamento_de_Año_Sabatico.pdf">Descargar</a>
							</div>
						</div><!-- plan end -->

						<!-- reglamento servicios escolares -->
						<div class="col-md-6 col-lg-2 wow fadeInUp mt-5" data-wow-delay="1s">
							<div class="plan text-center featured">
								<span class="plan-name">Reglamento de la Dirección de Servicios Escolares
									<small>PDF</small></span>
								<img src="./assets/images/reglamentos/Reglamento_de_la_Dirección_de_Servicios_Escoalres.jpg"
									alt="Imagen PDF" class="pdf-image">
								<a class="btn btn-outline-danger"
									href="https://sistemas.ujed.mx/manualesdipi/Reglamento_de_la_Dirección_de_Servicios_Escolares.pdf">Descargar</a>
							</div>
						</div><!-- plan end -->

						<!-- reglamento division de posgrado -->
						<div class="col-md-6 col-lg-2 wow fadeInUp mt-5" data-wow-delay="1s">
							<div class="plan text-center featured">
								<span class="plan-name">Reglamento de la División de Posgrado e Investigación
									<small>PDF</small></span>
								<img src="./assets/images/reglamentos/Reglamento_de_la_División_de_Posgrado_e_Investigación.jpg"
									alt="Imagen PDF" class="pdf-image">
								<a class="btn btn-outline-danger"
									href="https://sistemas.ujed.mx/manualesdipi/Reglamento_de_la_División_de_Posgrado_e_Investigación.pdf">Descargar</a>
							</div>
						</div><!-- plan end -->

						<!-- reglamento personal academico -->
						<div class="col-md-6 col-lg-2 wow fadeInUp mt-5" data-wow-delay="1s">
							<div class="plan text-center featured">
								<span class="plan-name">Reglamento de Personal Académico
									<small>PDF</small></span>
								<img src="./assets/images/reglamentos/Reglamento_de_Personal_Académico.jpg"
									alt="Imagen PDF" class="pdf-image">
								<a class="btn btn-outline-danger"
									href="https://sistemas.ujed.mx/manualesdipi/Reglamento_de_Personal_Academico.pdf">Descargar</a>
							</div>
						</div><!-- plan end -->

						<!-- Reglamento General de Investigación -->
						<div class="col-md-6 col-lg-2 wow fadeInUp mt-5" data-wow-delay="1s">
							<div class="plan text-center featured">
								<span class="plan-name" style="font-size: 20px;">Reglamento General de Investigación
									<small>PDF</small></span>
								<img src="./assets/images/reglamentos/Reglamento_general_de_Investigación.jpg"
									alt="Imagen PDF" class="pdf-image">
								<a class="btn btn-outline-danger"
									href="https://sistemas.ujed.mx/manualesdipi/Reglamento_General de_Investigación.pdf">Descargar</a>
							</div>
						</div><!-- plan end -->

							<!-- Reglamento_General_de la_UJED-->
							<div class="col-md-6 col-lg-2 wow fadeInUp mt-5" data-wow-delay="1s">
								<div class="plan text-center featured">
									<span class="plan-name" style="font-size: 20px;">Reglamento General de la UJED
										<small>PDF</small></span>
									<img src="./assets/images/reglamentos/Reglamento_General_de_la_UJED.jpg"
										alt="Imagen PDF" class="pdf-image">
									<a class="btn btn-outline-danger"
										href="https://sistemas.ujed.mx/manualesdipi/Reglamento_General_de_la_Universidad_Juárez_del_Estado_de_Durango.pdf">Descargar</a>
								</div>
							</div><!-- plan end -->
					</div>
				</div>
				<div class="tab-pane" id="tab2">

					<div class="row">
						<!-- Reglamento de investigación IIC -->
						<div class="col-md-6 col-lg-2 wow fadeInUp" data-wow-delay=".5s">
							<div class="plan text-center featured">

								<span class="plan-name"> Reglamento de investigación IIC
									<small>PDF</small></span>
								<img src="./assets/images/reglamentos/Instituto_de_Investigación_Científica.jpg" alt="Imagen PDF"
									class="pdf-image">
								<a class="btn btn-outline-danger"
									href="https://sistemas.ujed.mx/manualesdipi/REGLAMENTO-INVESTIGACI%C3%93N_IIC.pdf">Descargar</a>
							</div>
						</div><!-- plan end -->

						<!-- plan start -->
						<div class="col-md-6 col-lg-2 wow fadeInUp" data-wow-delay=".5s">
							<div class="plan text-center featured">
								<span class="plan-name"> Reglamento de Investigación FTS <small>PDF</small></span>
								<img src="./assets/images/reglamentos/reglamentos.jpeg" alt="Imagen PDF"
									class="pdf-image">
								<a class="btn btn-outline-danger"
									href="https://sistemas.ujed.mx/manualesdipi/REGLAMENTO_DE_INVESTIGACION_DE_LA_FTS.pdf">Descargar</a>
							</div>
						</div><!-- plan end -->

						<div class="col-md-6 col-lg-2 wow fadeInUp" data-wow-delay=".5s">
							<div class="plan text-center featured ">
								<span class="plan-name">Reglamento de Investigación FADER/CIPOL
									<small>PDF</small></span>
								<img src="./assets/images/reglamentos/Reglamento_de_InvestigaciónFADER.jpg" alt="Imagen PDF"
									class="pdf-image">
								<a class="btn btn-outline-danger"
									href="https://sistemas.ujed.mx/manualesdipi/Reglamento_de_InvestigaciónFADER.pdf">Descargar</a>
							</div>
						</div><!-- plan end -->

						<div class="col-md-6 col-lg-2 wow fadeInUp" data-wow-delay=".5s">
							<div class="plan text-center featured ">
								<span class="plan-name">Reglamento de la División de Estudios de Posgrado FADER/CIPOL
									<small>PDF</small></span>
								<img src="./assets/images/reglamentos/Reglamento_de_la_División_de_Estudios_de_PosgradoFADER.jpg" alt="Imagen PDF"
									class="pdf-image">
								<a class="btn btn-outline-danger"
									href="https://sistemas.ujed.mx/manualesdipi/Reglamento_PosgradoFADE.pdf">Descargar</a>
							</div>
						</div><!-- plan end -->


						<!-- plan start -->
						<div class="col-md-6 col-lg-2 wow fadeInUp" data-wow-delay=".5s">
							<div class="plan text-center featured">
								<span class="plan-name">Reglamento de Investigación FAZ <small>PDF</small></span>
								<img src="./assets/images/reglamentos/REGLAMENTO_DE_INVESTIGACIÓN_FAZ.jpg" alt="Imagen PDF"
									class="pdf-image">
								<a class="btn btn-outline-danger"
									href="https://sistemas.ujed.mx/manualesdipi/REGLAMENTO_DE_INVESTIGACI%C3%93N_FAZ.pdf">Descargar</a>
							</div>
						</div><!-- plan end -->

						<!-- plan start -->
						<div class="col-md-6 col-lg-2 wow fadeInUp" data-wow-delay=".5s">
							<div class="plan text-center featured">
								<span class="plan-name">Reglamento de la División de Estudios de Posgrado FAZ <small>PDF</small></span>
								<img src="./assets/images/reglamentos/Reglamento de la División de Estudios de PosgradoFAZ.jpg" alt="Imagen PDF"
									class="pdf-image">
								<a class="btn btn-outline-danger"
									href="https://sistemas.ujed.mx/manualesdipi/Reglamento de la División de Estudios de PosgradoFAZ.pdf">Descargar</a>
							</div>
						</div><!-- plan end -->

						

						<div class="col-md-6 col-lg-2 wow fadeInUp mt-5" data-wow-delay=".5s">
							<div class="plan text-center featured">
								<span class="plan-name">Reglamento de Investigación FCB <small>PDF</small></span>
								<img src="./assets/images/reglamentos/REGLAMENTO_DE_INVESTIGACIÓN_FCB.jpg" alt="Imagen PDF"
									class="pdf-image">
								<a class="btn btn-outline-danger"
									href="https://sistemas.ujed.mx/manualesdipi/REGLAMENTO_DE_INVESTIGACIÓN_FCB.pdf">Descargar</a>
							</div>
						</div><!-- plan end -->

						<div class="col-md-6 col-lg-2 wow fadeInUp mt-5" data-wow-delay=".7s">
							<div class="plan text-center featured">
								<span class="plan-name">Reglamento de la DEP FCB<small>PDF</small></span>
								<img src="./assets/images/reglamentos/Reglamento_de_la_División_de_Estudios_de_Posgrado.jpg" alt="Imagen PDF"
									class="pdf-image">
								<a class="btn btn-outline-danger"
									href="https://sistemas.ujed.mx/manualesdipi/REGLAMENTO_DE_LA_DEP_FCB.pdf">Descargar</a>
							</div>
						</div><!-- plan end -->

						<div class="col-md-6 col-lg-2 wow fadeInUp  mt-5" data-wow-delay=".5s">
							<div class="plan text-center featured">
								<span class="plan-name">Reglamento de Investigación FP y TCH <small>PDF</small></span>
								<img src="./assets/images/reglamentos/Reglamento_de_Investigación_FPyTCH.jpg" alt="Imagen PDF"
									class="pdf-image">
								<a class="btn btn-outline-danger"
									href="https://sistemas.ujed.mx/manualesdipi/Reglamento_de_Investigación_FPyTCH.pdf">Descargar</a>
							</div>
						</div><!-- plan end -->

						<div class="col-md-6 col-lg-2 wow fadeInUp  mt-5" data-wow-delay=".5s">
							<div class="plan text-center featured">
								<span class="plan-name">Reglamento de la División de Estudos de Posgrado FPyTCH <small>PDF</small></span>
								<img src="./assets/images/reglamentos/Reglamento_de_la_División_de_Estudios_de_Posgrado_FPyTCH.jpg" alt="Imagen PDF"
									class="pdf-image">
								<a class="btn btn-outline-danger"
									href="https://sistemas.ujed.mx/manualesdipi/FPYTCH-Reglamentos_DEP_Sellado.pdf">Descargar</a>
							</div>
						</div><!-- plan end -->

						<div class="col-md-6 col-lg-2 wow fadeInUp mt-5" data-wow-delay=".7s">
							<div class="plan text-center featured">
								<span class="plan-name">Reglamento de la DEP FMVZ <small>PDF</small></span>
								<img src="./assets/images/reglamentos/Reglamento_de_Posgrado_de_la_FMVZ.jpg" alt="Imagen PDF"
									class="pdf-image">
								<a class="btn btn-outline-danger"
									href="https://sistemas.ujed.mx/manualesdipi/REGLAMENTO_DE_LA_DEP_DE_FMVZ.pdf">Descargar</a>
							</div>
						</div><!-- plan end -->

					

						<div class="col-md-6 col-lg-2 wow fadeInUp mt-5" data-wow-delay="1s">
							<div class="plan text-center featured">
								<span class="plan-name">Reglamento Secretaria Posgrado FAMEN <small>PDF</small></span>
								<img src="./assets/images/reglamentos/Reglamento_de_Posgrado_e_Investigación.jpg" alt="Imagen PDF"
									class="pdf-image">
								<a class="btn btn-outline-danger"
									href="https://sistemas.ujed.mx/manualesdipi/Reglamento_de_la_Secretaria_de_posgradio_FAMEN.pdf">Descargar</a>
							</div>
						</div><!-- plan end -->

						<div class="col-md-6 col-lg-2 wow fadeInUp mt-5" data-wow-delay="1s">
							<div class="plan text-center featured">
								<span class="plan-name">Reglamento de la DEP FAEO<small>PDF</small></span>
								<img src="./assets/images/reglamentos/REGLAMENTO_DEP_FAEO.jpg" alt="Imagen PDF"
									class="pdf-image">
								<a class="btn btn-outline-danger"
									href="https://sistemas.ujed.mx/manualesdipi/REGLAMENTO_DEP_FAEO.pdf">Descargar</a>
							</div>
						</div><!-- plan end -->

						<div class="col-md-6 col-lg-2 wow fadeInUp mt-5" data-wow-delay="1s">
							<div class="plan text-center featured">
								<span class="plan-name">Reglamento de Investigación MCYH <small>PDF</small></span>
								<img src="./assets/images/reglamentos/REGLAMENTO_MCYH.jpg" alt="Imagen PDF"
									class="pdf-image">
								<a class="btn btn-outline-danger"
									href="https://sistemas.ujed.mx/manualesdipi/REGLAMENTO_MCYH.pdf">Descargar</a>
							</div>
						</div><!-- plan end -->

						<div class="col-md-6 col-lg-2 wow fadeInUp mt-5" data-wow-delay="1s">
							<div class="plan text-center featured">
								<span class="plan-name">Reglamento de Investigación MICAF <small>PDF</small></span>
								<img src="./assets/images/reglamentos/reglamentos.jpeg" alt="Imagen PDF"
									class="pdf-image">
								<a class="btn btn-outline-danger"
									href="https://sistemas.ujed.mx/manualesdipi/REGLAMENTO_MICAF.pdf">Descargar</a>
							</div>
						</div><!-- plan end -->

						<div class="col-md-6 col-lg-2 wow fadeInUp mt-5" data-wow-delay="1s">
							<div class="plan text-center featured">
								<span class="plan-name">Reglamento de Investigación FCE <small>PDF</small></span>
								<img src="./assets/images/reglamentos/Reglamento_de_InvestigaciónCIENCIAS_EXACTAS.jpg" alt="Imagen PDF"
									class="pdf-image">
								<a class="btn btn-outline-danger"
									href="https://sistemas.ujed.mx/manualesdipi/Reglamento_de_InvestigaciónCIENCIAS_EXACTAS.pdf">Descargar</a>
							</div>
						</div><!-- plan end -->

						<div class="col-md-6 col-lg-2 wow fadeInUp mt-5" data-wow-delay="1s">
							<div class="plan text-center featured">
								<span class="plan-name">Reglamento de la Dirección de Estudios de Posgrado FCE <small>PDF</small></span>
								<img src="./assets/images/reglamentos/Reglamento_de_la_Dirección_de_Estudios_de_PosgradoCienciasExactas.jpg" alt="Imagen PDF"
									class="pdf-image">
								<a class="btn btn-outline-danger"
									href="https://sistemas.ujed.mx/manualesdipi/Reglamento_de_la_Dirección_de_Estudios_de_PosgradoCienciasExactas.pdf">Descargar</a>
							</div>
						</div><!-- plan end -->

						<div class="col-md-6 col-lg-2 wow fadeInUp mt-5" data-wow-delay="1s">
							<div class="plan text-center featured">
								<span class="plan-name">Reglamento de Investigación FCS <small>PDF</small></span>
								<img src="./assets/images/reglamentos/Reglamento_de_Investigación_Ciencias_salud.jpg" alt="Imagen PDF"
									class="pdf-image">
								<a class="btn btn-outline-danger"
									href="https://sistemas.ujed.mx/manualesdipi/Reglamento_de_Investigación_Ciencias_salud.pdf">Descargar</a>
							</div>
						</div><!-- plan end -->

						<div class="col-md-6 col-lg-2 wow fadeInUp mt-5" data-wow-delay="1s">
							<div class="plan text-center featured">
								<span class="plan-name">Reglamento de la Dirección de Estudios de Posgrado FCS <small>PDF</small></span>
								<img src="./assets/images/reglamentos/Reglamento_de_la_Dirección_de_Estudios_de_PosgradoFCS.jpg" alt="Imagen PDF"
									class="pdf-image">
								<a class="btn btn-outline-danger"
									href="https://sistemas.ujed.mx/manualesdipi/Reglamento_de_la_Dirección_de_Estudios_de_PosgradoFCS.pdf">Descargar</a>
							</div>
						</div><!-- plan end -->

						<div class="col-md-6 col-lg-2 wow fadeInUp mt-5" data-wow-delay="1s">
							<div class="plan text-center featured">
								<span class="plan-name">Reglamento de Investigación Odontología <small>PDF</small></span>
								<img src="./assets/images/reglamentos/Reglamento_de_InvestigaciónODONTOLOGIA.jpg" alt="Imagen PDF"
									class="pdf-image">
								<a class="btn btn-outline-danger"
									href="https://sistemas.ujed.mx/manualesdipi/Reglamento_de_InvestigaciónODONTOLOGIA.pdf">Descargar</a>
							</div>
						</div><!-- plan end -->

					

						<div class="col-md-6 col-lg-2 wow fadeInUp mt-5" data-wow-delay="1s">
							<div class="plan text-center featured">
								<span class="plan-name">Reglamento de la División de Estudios de Pogrado de Odontología <small>PDF</small></span>
								<img src="./assets/images/reglamentos/Reglamento_de_la_División_de_Estudios_de_PogradoODONTOLOGIA.jpg" alt="Imagen PDF"
									class="pdf-image">
								<a class="btn btn-outline-danger"
									href="https://sistemas.ujed.mx/manualesdipi/Reglamento_de_la_División_de_Estudios_de_PogradoODONTOLOGIA.pdf">Descargar</a>
							</div>
						</div><!-- plan end -->

						<div class="col-md-6 col-lg-2 wow fadeInUp mt-5" data-wow-delay="1s">
							<div class="plan text-center featured">
								<span class="plan-name">Reglamento de Investigación FCCFYD <small>PDF</small></span>
								<img src="./assets/images/reglamentos/FCCFYD_Reglamento_INV_Sellado.jpg" alt="Imagen PDF"
									class="pdf-image">
								<a class="btn btn-outline-danger"
									href="https://sistemas.ujed.mx/manualesdipi/FCCFYD_Reglamento_INV_Sellado.pdf">Descargar</a>
							</div>
						</div><!-- plan end -->

						<div class="col-md-6 col-lg-2 wow fadeInUp mt-5" data-wow-delay="1s">
							<div class="plan text-center featured">
								<span class="plan-name">Reglamento de la División de Estudios de Posgrado FCCFYD <small>PDF</small></span>
								<img src="./assets/images/reglamentos/FCCFYD_Reglamento_DEP_Sellado.jpg" alt="Imagen PDF"
									class="pdf-image">
								<a class="btn btn-outline-danger"
									href="https://sistemas.ujed.mx/manualesdipi/FCCFYD_Reglamento_DEP_Sellado.pdf">Descargar</a>
							</div>
						</div><!-- plan end -->

						<div class="col-md-6 col-lg-2 wow fadeInUp mt-5" data-wow-delay="1s">
							<div class="plan text-center featured">
								<span class="plan-name">Reglamento interno de Doctorado en Biomedicina <small>PDF</small></span>
								<img src="./assets/images/reglamentos/Reglamento_interno_Biomedicina.jpg" alt="Imagen PDF"
									class="pdf-image">
								<a class="btn btn-outline-danger"
									href="https://sistemas.ujed.mx/manualesdipi/Reglamento_interno_Biomedicina.pdf">Descargar</a>
							</div>
						</div><!-- plan end -->

						<div class="col-md-6 col-lg-2 wow fadeInUp mt-5" data-wow-delay="1s">
							<div class="plan text-center featured">
								<span class="plan-name">Reglamento de Investigación FCF<small>PDF</small></span>
								<img src="./assets/images/reglamentos/Reglamento_de_InvestigaciónFORESTALES.jpg" alt="Imagen PDF"
									class="pdf-image">
								<a class="btn btn-outline-danger"
									href="https://sistemas.ujed.mx/manualesdipi/Reglamento_de_InvestigaciónFORESTALES.pdf">Descargar</a>
							</div>
						</div><!-- plan end -->


						<div class="col-md-6 col-lg-2 wow fadeInUp mt-5" data-wow-delay="1s">
							<div class="plan text-center featured">
								<span class="plan-name">Reglamento de Investigación FCQ Durango<small>PDF</small></span>
								<img src="./assets/images/reglamentos/Reglamento_InvestigaciónQUMICAS_DGO.jpg" alt="Imagen PDF"
									class="pdf-image">
								<a class="btn btn-outline-danger"
									href="https://sistemas.ujed.mx/manualesdipi/Reglamento_InvestigaciónQUMICAS_DGO.pdf">Descargar</a>
							</div>
						</div><!-- plan end -->

						<div class="col-md-6 col-lg-2 wow fadeInUp mt-5" data-wow-delay="1s">
							<div class="plan text-center featured">
								<span class="plan-name">Reglamento de la División de Estudios de Posgrado FCQ Durango<small>PDF</small></span>
								<img src="./assets/images/reglamentos/Reglamento_PosgradoQUIMICAS_DGO.jpg" alt="Imagen PDF"
									class="pdf-image">
								<a class="btn btn-outline-danger"
									href="https://sistemas.ujed.mx/manualesdipi/Reglamento_PosgradoQUIMICAS_DGO.pdf">Descargar</a>
							</div>
						</div><!-- plan end -->

						<div class="col-md-6 col-lg-2 wow fadeInUp mt-5" data-wow-delay="1s">
							<div class="plan text-center featured">
								<span class="plan-name">Reglamento de Investigación FCQ Gómez Palacio<small>PDF</small></span>
								<img src="./assets/images/reglamentos/Reglamento_InvestigaciónQUMICAS_GOMEZ.jpg" alt="Imagen PDF"
									class="pdf-image">
								<a class="btn btn-outline-danger"
									href="https://sistemas.ujed.mx/manualesdipi/Reglamento_InvestigaciónQUMICAS_GOMEZ.pdf">Descargar</a>
							</div>
						</div><!-- plan end -->

						<div class="col-md-6 col-lg-2 wow fadeInUp mt-5" data-wow-delay="1s">
							<div class="plan text-center featured">
								<span class="plan-name">Reglamento de la División de Estudios de Posgrado FCQ Gómez Palacio<small>PDF</small></span>
								<img src="./assets/images/reglamentos/Reglamento_PosgradoQUIMICAS_GOMEZ.jpg" alt="Imagen PDF"
									class="pdf-image">
								<a class="btn btn-outline-danger"
									href="https://sistemas.ujed.mx/manualesdipi/Reglamento_PosgradoQUIMICAS_GOMEZ.pdf">Descargar</a>
							</div>
						</div><!-- plan end -->

						<div class="col-md-6 col-lg-2 wow fadeInUp mt-5" data-wow-delay="1s">
							<div class="plan text-center featured">
								<span class="plan-name">Reglamento de Investigación FICA<small>PDF</small></span>
								<img src="./assets/images/reglamentos/Reglamento_InvestigaciónFICA.jpg" alt="Imagen PDF"
									class="pdf-image">
								<a class="btn btn-outline-danger"
									href="https://sistemas.ujed.mx/manualesdipi/Reglamento_InvestigaciónFICA.pdf">Descargar</a>
							</div>
						</div><!-- plan end -->

						<div class="col-md-6 col-lg-2 wow fadeInUp mt-5" data-wow-delay="1s">
							<div class="plan text-center featured">
								<span class="plan-name">Reglamento de la División de Estudios de Posgrado FICA<small>PDF</small></span>
								<img src="./assets/images/reglamentos/Reglamento_PosgradoFICA.jpg" alt="Imagen PDF"
									class="pdf-image">
								<a class="btn btn-outline-danger"
									href="https://sistemas.ujed.mx/manualesdipi/Reglamento_PosgradoFICA.pdf">Descargar</a>
							</div>
						</div><!-- plan end -->

						<div class="col-md-6 col-lg-2 wow fadeInUp mt-5" data-wow-delay="1s">
							<div class="plan text-center featured">
								<span class="plan-name">Doctorado Institucional en Investigación Educativa<small>PDF</small></span>
								<img src="./assets/images/reglamentos/DIE-Reglamento Interno.jpg" alt="Imagen PDF"
									class="pdf-image">
								<a class="btn btn-outline-danger"
									href="https://sistemas.ujed.mx/manualesdipi/DIE-Reglamento Interno.pdf">Descargar</a>
							</div>
						</div><!-- plan end -->

						<div class="col-md-6 col-lg-2 wow fadeInUp mt-5" data-wow-delay="1s">
							<div class="plan text-center featured">
								<span class="plan-name">Reglamento de Investigación ICS<small>PDF</small></span>
								<img src="./assets/images/reglamentos/ICSReglamento.png" alt="Imagen PDF"
									class="pdf-image">
								<a class="btn btn-outline-danger"
									href="https://sistemas.ujed.mx/manualesdipi/ICS-Reglamento_INV_Sellado.pdf">Descargar</a>
							</div>
						</div><!-- plan end -->

						<div class="col-md-6 col-lg-2 wow fadeInUp mt-5" data-wow-delay="1s">
							<div class="plan text-center featured">
								<span class="plan-name">Reglamento de Investigación ISIMA<small>PDF</small></span>
								<img src="./assets/images/reglamentos/Reglamento_de_Investigación_ISIMA.jpg" alt="Imagen PDF"
									class="pdf-image">
								<a class="btn btn-outline-danger"
									href="https://sistemas.ujed.mx/manualesdipi/Reglamento_de_Investigación_ISIMA.pdf">Descargar</a>
							</div>
						</div><!-- plan end -->

						<div class="col-md-6 col-lg-2 wow fadeInUp mt-5" data-wow-delay="1s">
							<div class="plan text-center featured">
								<span class="plan-name" style="font-size: 15px;">Programa Institucional de Doctorado en Ciencias Agropecuarias y Forestales<small>PDF</small></span>
								<img src="./assets/images/reglamentos/PIDCAF_Reglamento_Interno.jpg" alt="Imagen PDF"
									class="pdf-image">
								<a class="btn btn-outline-danger"
									href="https://sistemas.ujed.mx/manualesdipi/PIDCAF_Reglamento_Interno.pdf">Descargar</a>
							</div>
						</div><!-- plan end -->


						
						
						

					</div>
				</div>

			</div>
		</div><!-- End default tabs -->
		<div class="gap-40"></div>

	</div>
	<!--/  Container end-->

</section>
<!--/ Pricing table end -->



<app-footer></app-footer>